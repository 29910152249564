import React, { ReactElement } from "react";

import PageCard from "components/PageCard";
import Section from "components/Section";

import { Container, Heading, CardWrapper } from "./FeaturedNews.styled";
import FeaturedNewsProps from "./FeaturedNewsProps";

const FeaturedNews = ({
    identifier,
    heading,
    items,
    anchorName,
}: FeaturedNewsProps): ReactElement => {
    return (
        <Section theme={"lightgray"} alignItems="center" id={anchorName}>
            <Container key={identifier}>
                {heading && (
                    <Heading dangerouslySetInnerHTML={{ __html: heading }} />
                )}
                {items?.map((cardData, index) => {
                    return (
                        <CardWrapper key={`NewsCard${index}`}>
                            <PageCard
                                {...cardData}
                                size={index == 0 ? "large" : "medium"}
                            />
                        </CardWrapper>
                    );
                })}
            </Container>
        </Section>
    );
};

export default React.memo(FeaturedNews);
