import { styled } from "styled-components";

import { Container as GridContainer } from "style/components/Page";
import { styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    row-gap: 2.1875rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }

    ${MQ.FROM_XL} {
        row-gap: 3.5rem;
    }
`;

export const Heading = styled.h2`
    ${styleHeadingXL};
    grid-column: col-start 1 / span 3;
    margin-bottom: 0.4375rem;
    margin-top: 0;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 6;
        margin-bottom: 1.3125rem;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 1 / span 4;
        margin-bottom: 0.875rem;
    }
`;

export const CardWrapper = styled.div`
    grid-column: auto / span 4;

    ${MQ.FROM_M} {
        grid-column: auto / span 6;
    }

    &:first-of-type {
        grid-column: col-start 1 / span 4;
        grid-column: auto / span 4;

        ${MQ.FROM_M} {
            grid-column: auto / span 12;
        }
    }
`;
